@import '_variables.scss';

@media (max-width: 1399px) {

    .back {
    
        &__link {
            height: 42px; 
            font-size: 14px;
        }
    }
} // 1399

@media (max-width: 1199px) {

    .section-title {
        font-size: 36px;
        line-height: 118%;
    }

    .block-subtitle {
        font-size: 24px;
        line-height: 29px;
    }
} // 1199

@media (max-width: 991px) {
    
} // 991

@media (max-width: 767px) {

    .more {
        flex-direction: column;
    
        &__button {
            margin: 0 0 18px 0;
        }
    }

    .back {
        margin-bottom: 16px;
    
        &__link {
            height: 36px; 
            padding: 0 8px;

            &:before {
                width: 15px;
                height: 14px; 
                background-position: 0 -83px; 
                background-size: 300% auto;
                margin-right: 8px;
            }
        }
    }
} // 767

@media (max-width: 575px) {

} // 575

@media (max-width: 360px) {

    .container {max-width: 330px;}
} // 360