.collections {

    @media (max-width: 1199px) {

        padding: 83px 0 29px 0;

        &__carousel {
            padding-bottom: 34px;
        }

        &__item {
            width: 380px;
            min-height: 184px;
            padding: 21px 0 12px 27px;
            border-radius: 10.1133px  0;
            background-position: 60% 21px;
            background-size: 113px auto;
            margin-right: 19px;

            &-title {
                font-size: 20px;
                line-height: 142%;
                margin-bottom: 8px;
            }

            &-text {
                font-size: 16px;
                line-height: 101%;
                margin-bottom: 10px;
            }

            &-number {

                &-value {
                    height: 28px;
                    padding: 0 12px;
                    border-radius: 4.04532px;
                    font-size: 14px;
                    line-height: 28px;
                }
            }

            &-photo{
            
                &_1 {
                    max-width: 90px;
                    margin-right: -25px;
                }
    
                &_2 {
                    max-width: 106px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        
    }

    @media (max-width: 767px) {

        padding: 63px 0 54px 0;

        .slick-slider-prev,
        .slick-slider-next {
            display: none !important;
        }

        &__item {
            width: 300px;
            min-height: 147px;
            padding: 16px 0 10px 21px;
            border-radius: 8.07692px 0px;
            background-position: 60% 16px;
            background-size: 90px auto;
            margin-right: 16px;

            &-title {
                font-size: 18px;
                line-height: 126%;
                margin-bottom: 6px;
            }

            &-text {
                font-size: 14px;
                line-height: 107%;
                margin-bottom: 6px;
            }

            &-number {

                &-value {
                    height: 22px;
                    padding: 0 9px;
                    border-radius: 3.23077px;
                    font-size: 11px;
                    line-height: 22px;
                }
            }

            &-photo{
            
                &_1 {
                    max-width: 71px;
                    margin-right: -15px;
                }
    
                &_2 {
                    max-width: 85px;
                }
            }
        }
    }

    @media (max-width: 575px) {
        
    }
}
   