/* Defaults */
@media (max-width: 1399px) {
	.back__link {
		height: 42px;
		font-size: 14px;
	}
}

@media (max-width: 1199px) {
	.section-title {
		font-size: 36px;
		line-height: 118%;
	}
	.block-subtitle {
		font-size: 24px;
		line-height: 29px;
	}
}

@media (max-width: 767px) {
	.more {
		flex-direction: column;
	}
	.more__button {
		margin: 0 0 18px 0;
	}
	.back {
		margin-bottom: 16px;
	}
	.back__link {
		height: 36px;
		padding: 0 8px;
	}
	.back__link:before {
		width: 15px;
		height: 14px;
		background-position: 0 -83px;
		background-size: 300% auto;
		margin-right: 8px;
	}
}

@media (max-width: 360px) {
	.container {
		max-width: 330px;
	}
}

@media (max-width: 991px) {
	.form_horisontal .form__item {
		margin-right: 10px;
	}
	.form_horisontal .form__item:last-child {
		margin-right: 0;
	}
	.form_horisontal .form__button {
		font-size: 16px;
	}
}

@media (max-width: 767px) {
	.form__items {
		flex-flow: column;
	}
	.form__item {
		min-height: 60px;
	}
	.form__item_textarea {
		min-height: 148px;
	}
	.form__input {
		width: 100%;
		font-size: 14px;
	}
	.form_vertical {
		max-width: 428px;
		margin: 0 auto;
	}
	.form_horisontal {
		max-width: 428px;
		margin: 0 auto;
	}
	.form_horisontal .form__item {
		margin: 0;
	}
}

/* Blocks */
@media (max-width: 1199px) {
	.topbar__box {
		max-width: 832px;
		padding-left: 32px;
	}
	.topbar__left {
		flex: 0 0 289px;
	}
	.topbar__right {
		width: 100%;
		max-width: 750px;
		padding-left: 30px;
	}
	.topbar__contacts {
		padding: 27px 21px 10px 24px;
		border-radius: 4.65069px;
		font-size: 12px;
	}
	.topbar__contacts-title {
		font-size: 16px;
		margin-bottom: 9px;
	}
	.topbar__contacts-description {
		line-height: 155%;
		margin-bottom: 12px;
	}
	.topbar__contacts-list-title {
		padding-top: 12px;
		font-size: 12px;
		margin-bottom: 9px;
	}
	.topbar__contacts-list-value {
		line-height: 120%;
		margin-bottom: 12px;
	}
	.topbar__menu {
		padding-top: 28px;
	}
	.topbar__menu-block {
		padding-bottom: 16px;
	}
	.topbar__menu-title {
		font-size: 12px;
		margin-bottom: 14px;
	}
	.topbar__menu-item {
		font-size: 14px;
		line-height: 17px;
		margin-bottom: 13px;
	}
}

@media (max-width: 991px) {
	.topbar__box {
		padding-left: 0;
	}
	.topbar__menu-title {
		font-size: 11px;
	}
	.topbar__menu-item {
		font-size: 13px;
		line-height: 15px;
		margin-bottom: 11px;
	}
}

@media (max-width: 767px) {
	.topbar__box {
		flex-direction: column;
	}
	.topbar__left {
		flex: 0 0 100%;
	}
	.topbar__right {
		width: 100%;
		max-width: none;
		padding-left: 0;
	}
}

@media (max-width: 575px) {
	.topbar__menu {
		flex-wrap: wrap;
		padding-top: 38px;
	}
	.topbar__menu-col {
		flex: 0 0 50%;
	}
	.topbar__menu-col:last-child {
		display: flex;
		flex: 0 0 100%;
	}
	.topbar__menu-col:last-child .topbar__menu-block {
		flex: 0 0 50%;
	}
	.topbar__menu-block {
		padding-bottom: 20px;
	}
	.topbar__menu-title {
		font-size: 10.883px;
		line-height: 13px;
		margin-bottom: 10px;
	}
	.topbar__menu-item {
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 10px;
	}
	.topbar__trigger-text_desk {
		display: none;
	}
	.topbar__trigger-text_mob {
		display: inline;
	}
}

@media (max-width: 360px) {
	.topbar__box {
		max-width: 300px;
	}
}

@media (max-width: 1399px) {
	.header__menu-item {
		font-size: 15px;
		margin: 0 10px;
	}
	.header .search__form-input {
		font-size: 16px;
	}
}

@media (max-width: 1199px) {
	.header__icon {
		display: block;
		margin-right: 24px;
	}
	.header_nofix .header__icon {
		display: none;
	}
	.header__logo {
		max-width: 218px;
	}
	.header__menu {
		display: none;
		position: fixed;
		z-index: 10;
		top: 80px;
		bottom: 0;
		left: 0;
		right: 0;
		background: #fff;
		padding: 76px 15px 30px;
		max-width: 325px;
		height: 100%;
		max-height: 500px;
		min-height: 350px;
		overflow-y: scroll;
	}
	.header__menu-item {
		font-size: 16px;
		margin: 0 0 48px 0;
	}
	.header__basket {
		font-size: 16px;
		line-height: 133.98%;
		margin-right: 38px;
	}
	.header__basket:before {
		width: 20px;
		height: 20px;
		background-size: 39px auto;
		background-position: 0 -56px;
		vertical-align: -4px;
	}
	.header__profile {
		width: 44px;
		height: 44px;
		background-size: 44px auto;
	}
	.header .search {
		max-width: 50%;
		padding-top: 5px;
	}
	.page-scrolled .header .search {
		padding: 0 0 10px 0;
	}
	.header .search__form {
		height: 50px;
	}
	.page-scrolled .header .search__form {
		height: 40px;
	}
	.header .search__form:before {
		left: 27px;
		width: 24px;
		height: 24px;
		background-position: 0 -150px;
		background-size: 40px auto;
	}
	.header .search__form-item {
		padding: 0 10px 0 68px;
	}
}

@media (max-width: 767px) {
	.header__box {
		min-height: 74px;
	}
	.page-scrolled .header__box {
		min-height: 64px;
	}
	.header__logo {
		max-width: 170px;
	}
	.header__menu {
		top: 50px;
	}
	.header__basket {
		display: none;
	}
	.header__profile {
		width: 24px;
		height: 24px;
		background-size: 24px auto;
	}
	.header .search {
		max-width: 100%;
	}
	.header .search__form {
		height: 32px;
		border-radius: 8.72224px;
	}
	.page-scrolled .header .search__form {
		height: 32px;
	}
	.header .search__form:before {
		left: 15px;
		width: 15px;
		height: 15px;
		background-position: 0 -93px;
		background-size: 25px auto;
	}
	.header .search__form-item {
		padding: 0 10px 0 42px;
	}
	.header .search__form-input {
		font-size: 12px;
	}
}

@media (max-width: 360px) {
	.header__menu {
		padding: 76px 30px 30px;
	}
}

@media (max-width: 1499px) {
	.main__item-figure {
		max-width: 60%;
		left: 32%;
	}
	.main__item-book {
		max-width: 20%;
	}
}

@media (max-width: 1199px) {
	.main {
		padding-top: 105px;
	}
	.main__carousel .slick-slide {
		height: auto;
		min-height: 458px;
	}
	.main__item-box {
		padding: 0 87px 0 59px;
	}
	.main__item-info {
		flex: 0 0 55%;
		padding: 51px 0 40px 0;
	}
	.main__item-subtitle {
		font-size: 10px;
		line-height: 120%;
		margin-bottom: 32px;
	}
	.main__item-author {
		font-size: 24px;
		line-height: 112%;
		margin-bottom: 13px;
	}
	.main__item-title {
		font-size: 44px;
		line-height: 109%;
	}
	.main__item-text {
		font-size: 14px;
		line-height: 143%;
		margin-bottom: 34px;
	}
	.main__item-figure {
		max-width: 80%;
		left: 40%;
	}
	.main__item-figure-img_desk {
		display: none !important;
	}
	.main__item-figure-img_mob {
		display: block !important;
	}
	.main__item-book {
		display: none;
	}
}

@media (max-width: 767px) {
	.main {
		padding-top: 25px;
	}
	.main__carousel {
		margin: 0 -15px;
		padding-bottom: 20px;
	}
	.main .slick-arrow {
		display: none !important;
	}
	.main__item {
		border-radius: 0;
		overflow: hidden;
	}
	.main__item-title {
		font-size: 36px;
		line-height: 109%;
	}
	.main__item-box {
		min-height: none;
		padding: 0 30px 0 30px;
	}
	.main__item-info {
		flex: 0 0 70%;
		padding: 37px 0 40px 0;
	}
	.main__item-figure {
		left: auto;
		right: -20%;
		max-width: 90%;
	}
	.main__item-figure-img_desk {
		display: none !important;
	}
	.main__item-figure-img_mob {
		display: block !important;
	}
}

@media (max-width: 1199px) {
	.books {
		padding: 87px 0 71px 0;
	}
	.books__panel {
		margin-bottom: 37px;
	}
	.books__head {
		margin-right: 98px;
	}
	.books .tabs__title {
		font-size: 16px;
	}
	.books .tabs__title SPAN {
		padding-top: 0px;
	}
	.books__col {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.books__col_whide {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.books__label {
		top: -14px;
		right: 10px;
		height: 29px;
		padding: 0 12px;
		font-size: 13px;
	}
	.books__label_discount {
		font-weight: 600;
		font-size: 15px;
	}
	.books__photo {
		margin-bottom: 23px;
	}
	.books__price {
		font-size: 16px;
		line-height: 107%;
		margin-bottom: 17px;
	}
	.books__name {
		font-size: 20px;
		line-height: 89%;
		margin-bottom: 6px;
	}
	.books__text {
		font-size: 16px;
		line-height: 125%;
	}
	.books__settitle {
		font-size: 32px;
		line-height: 107.18%;
	}
	.books__settext {
		font-size: 16px;
		line-height: 29.09px;
		margin-bottom: 14px;
	}
	.books__setprice {
		font-size: 20px;
		line-height: 102%;
	}
	.books__col_whide .books__item-inner {
		padding: 43px 40px;
	}
}

@media (max-width: 991px) {
	.books__panel {
		flex-direction: column;
		margin-bottom: 23px;
	}
	.books__head {
		margin: 0 0 25px 0;
	}
	.books__content {
		margin-right: -10px;
		margin-left: -10px;
	}
	.books__col {
		flex: 0 0 50%;
		max-width: 50%;
		padding-right: 10px;
		padding-left: 10px;
	}
	.books__col_whide {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.books__label {
		padding: 0 5px;
		font-size: 12px;
	}
	.books__label_discount {
		font-size: 14px;
	}
	.books__settitle {
		font-size: 20px;
	}
	.books__settext {
		font-size: 10.82px;
		line-height: 19.68px;
		margin-bottom: 10px;
	}
	.books__setprice {
		font-size: 18px;
		line-height: 102%;
	}
	.books__col_whide .books__item-inner {
		padding: 29px;
	}
}

@media (max-width: 767px) {
	.books {
		padding: 64px 0 70px 0;
	}
	.books .tabs {
		display: block;
	}
}

@media (max-width: 1199px) {
	.collections {
		padding: 83px 0 29px 0;
	}
	.collections__carousel {
		padding-bottom: 34px;
	}
	.collections__item {
		width: 380px;
		min-height: 184px;
		padding: 21px 0 12px 27px;
		border-radius: 10.1133px  0;
		background-position: 60% 21px;
		background-size: 113px auto;
		margin-right: 19px;
	}
	.collections__item-title {
		font-size: 20px;
		line-height: 142%;
		margin-bottom: 8px;
	}
	.collections__item-text {
		font-size: 16px;
		line-height: 101%;
		margin-bottom: 10px;
	}
	.collections__item-number-value {
		height: 28px;
		padding: 0 12px;
		border-radius: 4.04532px;
		font-size: 14px;
		line-height: 28px;
	}
	.collections__item-photo_1 {
		max-width: 90px;
		margin-right: -25px;
	}
	.collections__item-photo_2 {
		max-width: 106px;
	}
}

@media (max-width: 767px) {
	.collections {
		padding: 63px 0 54px 0;
	}
	.collections .slick-slider-prev,
	.collections .slick-slider-next {
		display: none !important;
	}
	.collections__item {
		width: 300px;
		min-height: 147px;
		padding: 16px 0 10px 21px;
		border-radius: 8.07692px 0px;
		background-position: 60% 16px;
		background-size: 90px auto;
		margin-right: 16px;
	}
	.collections__item-title {
		font-size: 18px;
		line-height: 126%;
		margin-bottom: 6px;
	}
	.collections__item-text {
		font-size: 14px;
		line-height: 107%;
		margin-bottom: 6px;
	}
	.collections__item-number-value {
		height: 22px;
		padding: 0 9px;
		border-radius: 3.23077px;
		font-size: 11px;
		line-height: 22px;
	}
	.collections__item-photo_1 {
		max-width: 71px;
		margin-right: -15px;
	}
	.collections__item-photo_2 {
		max-width: 85px;
	}
}

@media (max-width: 1199px) {
	.mentors {
		padding: 67px 0 87px;
	}
	.mentors__content {
		margin-right: -22px;
		margin-left: -22px;
	}
	.mentors__col {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
		padding-right: 22px;
		padding-left: 22px;
	}
	.mentors__item {
		padding-bottom: 32px;
	}
	.mentors__photo {
		margin-bottom: 28px;
	}
	.mentors__photo IMG {
		border-radius: 8.87619px 0;
	}
	.mentors__name {
		font-size: 24px;
		line-height: 118%;
		margin-bottom: 7px;
	}
	.mentors__post {
		line-height: 133%;
		letter-spacing: 0;
	}
}

@media (max-width: 991px) {
	.mentors__content {
		margin-right: -8px;
		margin-left: -8px;
	}
	.mentors__col {
		flex: 0 0 50%;
		max-width: 50%;
		padding-right: 8px;
		padding-left: 8px;
	}
	.mentors__item {
		padding-bottom: 35px;
	}
	.mentors__photo {
		margin-bottom: 26px;
	}
	.mentors__photo IMG {
		border-radius: 5.65118px 0;
	}
	.mentors__name {
		font-size: 22px;
		line-height: 122%;
	}
	.mentors__post {
		font-size: 12px;
		line-height: 130%;
	}
}

@media (max-width: 767px) {
	.mentors {
		padding: 59px 0 49px;
	}
}

@media (max-width: 1699px) {
	.cooperation__right {
		padding: 5% 4% 0 4%;
	}
}

@media (max-width: 1199px) {
	.cooperation__box {
		flex-direction: column;
	}
	.cooperation__left {
		flex: 0 0 100%;
		padding: 80px 59px 88px 59px;
		background: linear-gradient(95.05deg, #5957B1 31.53%, #4595B7 105.34%);
	}
	.cooperation__right {
		flex: 0 0 100%;
		padding: 107px 5% 45px 5%;
	}
	.cooperation__right:before {
		top: -68px;
		width: 286px;
		height: 147px;
		background-size: 100% auto;
	}
	.cooperation__title {
		margin-bottom: 31px;
	}
}

@media (max-width: 767px) {
	.cooperation__left {
		padding: 59px 32px;
	}
	.cooperation__right {
		padding: 75px 32px 52px 32px;
	}
	.cooperation__right:before {
		top: -39px;
		width: 164px;
		height: 85px;
	}
	.cooperation__description {
		margin-bottom: 33px;
	}
	.cooperation__item {
		font-size: 17px;
		line-height: 141%;
	}
	.cooperation__text {
		font-size: 20px;
		line-height: 110%;
		margin-bottom: 7px;
	}
	.cooperation__title {
		font-size: 36px;
		margin-bottom: 35px;
	}
}

@media (max-width: 360px) {
	.cooperation__box {
		margin: 0 -45px;
	}
	.cooperation__left {
		padding: 59px 45px;
		border-radius: 0;
	}
	.cooperation__right {
		padding: 75px 45px 52px 45px;
		border-radius: 0;
	}
}

@media (max-width: 1199px) {
	.events {
		padding: 172px 0 70px 0;
	}
	.events__content {
		margin-right: -10px;
		margin-left: -10px;
		margin-bottom: 12px;
	}
	.events__col {
		padding-right: 10px;
		padding-left: 10px;
		padding-bottom: 20px;
	}
	.events__info {
		left: 25px;
		right: 120px;
		bottom: 25px;
	}
	.main .events__info {
		right: 150px;
	}
	.events__date {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 9px;
	}
	.events__name {
		font-size: 18.0688px;
		line-height: 123%;
	}
	.main .events__name {
		font-size: 32px;
		line-height: 111%;
		margin-bottom: 6px;
	}
	.events__text {
		font-size: 16px;
		line-height: 128%;
	}
	.events__book {
		right: 25px;
		bottom: 25px;
		box-shadow: 11.8134px 11.8134px 23.6269px rgba(0, 0, 0, 0.23);
	}
	.events__book-img {
		max-width: 76px;
	}
	.main .events__book-img {
		max-width: 100px;
	}
}

@media (max-width: 767px) {
	.events {
		padding: 46px 0 56px 0;
	}
	.events__content {
		flex-direction: column;
		align-items: center;
	}
	.events__col {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.events__item {
		max-width: 576px;
	}
	.main .events__item {
		max-width: none;
	}
	.events__info {
		left: 20px;
		right: 100px;
		bottom: 20px;
	}
	.main .events__info {
		right: 100px;
	}
	.events__date {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 7px;
	}
	.events__name {
		font-size: 16px;
		line-height: 109%;
	}
	.main .events__name {
		font-size: 16px;
		line-height: 109%;
		margin-bottom: 0;
	}
	.events__text {
		display: none;
	}
	.events__book {
		right: 20px;
		bottom: 20px;
	}
	.events__book-img {
		max-width: 60px;
	}
	.main .events__book-img {
		max-width: 60px;
	}
}

@media (max-width: 1199px) {
	.news {
		padding: 56px 0 70px 0;
	}
	.news__title {
		margin-bottom: 27px;
	}
	.news__content {
		margin-right: -10px;
		margin-left: -10px;
		margin-bottom: 12px;
	}
	.news__col {
		padding-right: 10px;
		padding-left: 10px;
		padding-bottom: 20px;
	}
	.news__info {
		left: 25px;
		right: 25px;
		bottom: 25px;
	}
	.news__date {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 9px;
	}
	.news__name {
		font-size: 18.0688px;
		line-height: 123%;
	}
	.main .news__name {
		font-size: 32px;
		line-height: 111%;
		margin-bottom: 6px;
	}
	.news__text {
		font-size: 16px;
		line-height: 128%;
	}
}

@media (max-width: 767px) {
	.news {
		padding: 59px 0 70px 0;
	}
	.news__title {
		margin-bottom: 30px;
	}
	.news__content {
		flex-direction: column;
		align-items: center;
	}
	.news__col {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.news__item {
		max-width: 576px;
	}
	.main .news__item {
		max-width: none;
	}
	.news__info {
		left: 20px;
		right: 20px;
		bottom: 20px;
	}
	.news__hashtag {
		display: none;
	}
	.news__date {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 7px;
	}
	.news__name {
		font-size: 16px;
		line-height: 109%;
	}
	.main .news__name {
		font-size: 16px;
		line-height: 109%;
		margin-bottom: 12px;
	}
	.news__text {
		display: none;
	}
}

@media (max-width: 1199px) {
	.partners {
		padding: 0 0 87px;
	}
	.partners__title {
		margin-bottom: 27px;
	}
	.partners__list LI {
		font-size: 18px;
	}
}

@media (max-width: 767px) {
	.partners {
		padding: 0 0 49px;
	}
	.partners__title {
		margin-bottom: 30px;
	}
	.partners__list {
		flex-direction: column;
		gap: 20px;
	}
	.partners__list LI {
		font-size: 16px;
	}
}

@media (max-width: 991px) {
	.footer__box {
		padding-left: 0;
	}
}

@media (max-width: 1199px) {
	.page-scrolled .bottom-menu {
		display: block;
	}
}

@media (max-width: 767px) {
	.bottom-menu {
		padding: 9px 0 8px 0;
	}
}

/* Filters -----------------------------------------------------------------------------------------------*/
@media (max-width: 575px) {
	.filters-section_fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		width: 100%;
		height: 100%;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		transform: translateZ(0);
	}
	.filters-section_fixed .filters-section__inner {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 101;
		width: 100%;
		height: auto;
		max-height: 100%;
		overflow: auto;
	}
	.filters__head {
		display: block;
		flex: 0 0 100%;
		margin-bottom: 20px;
	}
	.filters__head-title {
		font-weight: 600;
		font-size: 14px;
		line-height: 129%;
		letter-spacing: 0.2px;
		color: #13A8FC;
	}
	.filters__head-title:before {
		content: '';
		display: inline-block;
		width: 15px;
		height: 14px;
		background: url(../img/common/icons.png) no-repeat -25px -80px;
		vertical-align: -2px;
		margin-right: 10px;
	}
	.filters__head-back, .filters__head-close {
		display: none;
	}
	.filters_fixed {
		padding: 70px 30px 80px 30px;
		background: #fff;
		margin: 0;
	}
	.filters_fixed:before {
		content: '';
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #fff;
	}
	.filters_fixed .filters__head {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 102;
		display: block;
		height: 60px;
		padding-top: 20px;
		background: #fff;
		margin: 0;
	}
	.filters_fixed .filters__head-back {
		position: absolute;
		top: 25px;
		left: 30px;
		display: none;
		width: 7px;
		height: 12px;
		background: url(../img/common/icons.png) no-repeat 0 -80px;
		transform: rotate(-180deg);
		text-indent: -10000px;
	}
	.filters_fixed .filters__head-title {
		font-weight: 600;
		font-size: 16px;
		line-height: 133.98%;
		color: #1C2734;
		text-align: center;
	}
	.filters_fixed .filters__head-title:before {
		display: none;
	}
	.filters_fixed .filters__head-close {
		position: absolute;
		top: 20px;
		right: 30px;
		display: block;
		font-size: 15px;
		line-height: 20px;
		color: #13A8FC;
	}
	.filters_fixed .filter {
		display: block;
	}
	.filters_fixed .filter__drop .scrollblock {
		max-height: 100%;
	}
	.filters_fixed .filters__footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 103;
		display: block;
		padding: 30px;
		background: #fff;
	}
	.filters_fixed .filters__footer-link {
		height: 40px;
		border-radius: 4px;
		background: #13A8FC;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.2px;
		color: #fff;
	}
	.filter {
		display: none;
		width: 100%;
		padding-bottom: 10px;
		border-bottom: 1px solid #9FAEBF;
	}
	.filter__name {
		position: relative;
		width: 100%;
		height: auto;
		padding: 0;
		border: 0;
		border-radius: 0;
		font-weight: 600;
		font-size: 15px;
		line-height: 50px;
		color: #1C2734;
		cursor: auto;
		margin-right: 0;
	}
	.filter__name:after {
		display: none;
	}
	.filter__name-open {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translate(0, -50%);
		display: block;
		width: 7px;
		height: 12px;
		background: url(../img/common/icons.png) no-repeat 0 -80px;
		text-indent: -10000px;
	}
	.filter_price .filter__drop,
	.filter_collections .filter__drop,
	.filter_type .filter__drop {
		display: block;
		position: static;
		padding: 0;
		box-shadow: none;
		border: 0;
		border-radius: 0;
	}
	.filter_collections .filter__list,
	.filter_type .filter__list {
		display: flex;
		flex-wrap: wrap;
	}
	.filter_collections .filter__list-item,
	.filter_type .filter__list-item {
		margin: 0 8px 8px 0;
	}
	.filter_collections .filter__list-label,
	.filter_type .filter__list-label {
		position: relative;
		height: 32px;
		background: #F8F8F8;
		font-size: 14px;
		border-radius: 6px;
		overflow: hidden;
		margin: 0;
	}
	.filter_collections .filter__list-label > DIV,
	.filter_type .filter__list-label > DIV {
		position: relative;
		z-index: 3;
		padding: 0 12px;
	}
	.filter_collections .filter__list-label .checkbox-box,
	.filter_type .filter__list-label .checkbox-box {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}
	.filter_collections .filter__list-label .checkbox-box .checkbox,
	.filter_type .filter__list-label .checkbox-box .checkbox {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
	}
	.filter_collections .filter__list-label .checkbox-box .checkbox + .checkbox-icon,
	.filter_type .filter__list-label .checkbox-box .checkbox + .checkbox-icon {
		top: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: none;
	}
	.filter_collections .filter__list-label .checkbox-box .checkbox:checked + .checkbox-icon,
	.filter_type .filter__list-label .checkbox-box .checkbox:checked + .checkbox-icon {
		background: rgba(19, 168, 252, 0.1);
	}
	.filter_author .filter__drop {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 101;
		width: 100%;
		height: auto;
		max-height: 100%;
		overflow: auto;
		display: none;
		padding: 70px 30px 90px 30px;
		background: #fff;
		box-shadow: none;
		border: 0;
		border-radius: 0;
	}
	.filter_author .filter__list-item {
		border-bottom: 1px solid #9FAEBF;
		margin-bottom: 8px;
	}
	.filter_author .filter__list-item:last-child {
		border: 0;
	}
	.filter_author .filter__dropfix {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		width: 100%;
		height: 100%;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		transform: translateZ(0);
		background: #fff;
	}
	.filter_author .checkbox-box .checkbox {
		top: -8px;
	}
	.filter_author .checkbox-box .checkbox,
	.filter_author .checkbox-box .checkbox + .checkbox-icon {
		width: 16px;
		height: 16px;
	}
	.filter_author .checkbox-box .checkbox + .checkbox-icon {
		top: -8px;
		background: none;
		border: 1px solid #DADEE2;
		border-radius: 2px;
	}
	.filter_author .checkbox-box .checkbox:checked + .checkbox-icon {
		border-color: #13A8FC;
		background: none;
	}
	.filter_author .checkbox-box .checkbox:checked + .checkbox-icon:after {
		content: '';
		display: block;
		width: 13px;
		height: 11px;
		background: url(../img/common/icons.png) no-repeat 0 -100px;
		margin: -1px -2px auto auto;
	}
	.choice-value {
		height: 32px;
		padding: 0 28px 0 12px;
		margin-right: 8px;
	}
	.choice-value-del {
		right: 12px;
	}
	.choice-delete {
		width: 32px;
		height: 32px;
		margin-right: 8px;
		margin-bottom: 8px;
	}
	.price-filter {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.price-filter__head {
		margin-bottom: 8px;
	}
	.price-filter__slider {
		order: -1;
		margin-bottom: 13px;
	}
	.price-filter__slider SPAN {
		top: -8px;
		width: 16px;
		height: 16px;
		margin-left: -8px;
	}
}

/* /Filters -----------------------------------------------------------------------------------------------*/
