/* Filters -----------------------------------------------------------------------------------------------*/
@import 'common/_variables';

@media (max-width: 575px) {

    .filters-section {

        &_fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
            width: 100%;
            height: 100%;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            transform: translateZ(0);
        }

        &__inner {

            .filters-section_fixed & {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 101;
                width: 100%;
                height: auto;
                max-height: 100%;
                overflow: auto;
            }
        }
    }

    .filters {

        &__head {
            display: block;
            flex: 0 0 100%;
            margin-bottom: 20px;

            &-title {
                font-weight: 600;
                font-size: 14px;
                line-height: 129% ;
                letter-spacing: 0.2px;
                color: $lightblue;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 15px;
                    height: 14px;
                    background: url(../img/common/icons.png) no-repeat -25px -80px;
                    vertical-align: -2px;
                    margin-right: 10px;
                }
            }

            &-back,
            &-close {
                display: none;
            }
        }

        &_fixed {
            padding: 70px 30px 80px 30px;
            background: #fff;
            margin: 0;

            &:before {
                content: '';
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: #fff;
            }

            .filters__head {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 102;
                display: block;
                height: 60px;
                padding-top: 20px;
                background: #fff;
                margin: 0;

                &-back {
                    position: absolute;
                    top: 25px;
                    left: 30px;
                    display: none;
                    width: 7px;
                    height: 12px;
                    background: url(../img/common/icons.png) no-repeat 0 -80px;
                    transform: rotate(-180deg);
                    text-indent: -10000px;
                }

                &-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 133.98%;
                    color: #1C2734;
                    text-align: center;

                    &:before {
                        display: none;
                    }
                }

                &-close {
                    position: absolute;
                    top: 20px;
                    right: 30px;
                    display: block;
                    font-size: 15px;
                    line-height: 20px;
                    color: $lightblue;
                }
            }

            .filter {
                display: block;

                &__drop {
            
                    .scrollblock {
                        max-height: 100%;
                    }
                }
            }

            .filters__footer {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 103;
                display: block;
                padding: 30px;
                background: #fff;

                &-link {
                    height: 40px;
                    border-radius: 4px;
                    background: $lightblue;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: 0.2px;
                    color: #fff;
                }
            }
        }
    }

    .filter {
        display: none;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #9FAEBF;

        &__name {
            position: relative;
            width: 100%;
            height: auto;
            padding: 0;
            border: 0;
            border-radius: 0;
            font-weight: 600;
            font-size: 15px;
            line-height: 50px;
            color: #1C2734;
            cursor: auto;
            margin-right: 0;

            &:after {
                display: none;
            }

            &-open {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(0, -50%);
                display: block;
                width: 7px;
                height: 12px;
                background: url(../img/common/icons.png) no-repeat 0 -80px;
                text-indent: -10000px;
            }
        }

        &_price &,
        &_collections &,
        &_type & {
            
            &__drop {
                display: block;
                position: static;
                padding: 0;
                box-shadow: none;
                border: 0;
                border-radius: 0;
            }
        }

        &_collections &,
        &_type & {

            &__list {
                display: flex;
                flex-wrap: wrap;

                &-item {
                    margin: 0 8px 8px 0;
                }

                &-label {
                    position: relative;
                    height: 32px;
                    background: #F8F8F8;
                    font-size: 14px;
                    border-radius: 6px;
                    overflow: hidden;
                    margin: 0;

                    & > DIV {
                        position: relative;
                        z-index: 3;
                        padding: 0 12px;
                    }

                    .checkbox-box {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        margin: 0;

                        .checkbox {
                            top: 0; 
                            right: 0;
                            bottom: 0;
                            left: 0;
                            height: 100%;
                        }

                        .checkbox + .checkbox-icon {
                            top: 0; 
                            z-index: 0;
                            width: 100%;
                            height: 100%;
                            background: none;
                        }

                        .checkbox:checked + .checkbox-icon {
                            background: rgba(19, 168, 252, 0.1);
                        }
                    }
                }
            }
        }

        &_author & {
            
            &__drop {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 101;
                width: 100%;
                height: auto;
                max-height: 100%;
                overflow: auto;
                display: none;
                padding: 70px 30px 90px 30px;
                background: #fff;
                box-shadow: none;
                border: 0;
                border-radius: 0;
            }

            &__list {

                &-item {
                    border-bottom: 1px solid #9FAEBF;
                    margin-bottom: 8px;

                    &:last-child {
                        border: 0;
                    }
                }
            }
        }

        &_author {

            .filter__dropfix {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 100;
                width: 100%;
                height: 100%;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
                transform: translateZ(0);
                background: #fff;
            }

            .checkbox-box {
    
                .checkbox {
                    top: -8px; 
                }
        
                .checkbox,
                .checkbox + .checkbox-icon {
                    width: 16px; 
                    height: 16px; 
                }
            
                .checkbox + .checkbox-icon {
                    top: -8px; 
                    background: none; 
                    border: 1px solid #DADEE2;
                    border-radius: 2px;
                }
        
                .checkbox:checked + .checkbox-icon {
                    border-color: $lightblue;
                    background: none;
        
                    &:after {
                        content:'';
                        display: block;
                        width: 13px;
                        height: 11px;
                        background: url(../img/common/icons.png) no-repeat 0 -100px;
                        margin: -1px -2px auto auto;
                    }
                }
            }
        }
    }

    .choice {

        &-value {
            height: 32px;
            padding: 0 28px 0 12px;
            margin-right: 8px;

            &-del {
                right: 12px;
            }
        }

        &-delete {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    .price-filter {
        display: flex;
        flex-direction: column;
        width: 100%;
    
        &__head {
            margin-bottom: 8px;
        }
    
        &__slider {
            order: -1;
            margin-bottom: 13px;
    
            SPAN {
                top: -8px;
                width: 16px;
                height: 16px;
                margin-left: -8px;
            }
        }
    }
}
/* /Filters -----------------------------------------------------------------------------------------------*/
