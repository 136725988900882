.mentors {

    @media (max-width: 1199px) {

        padding: 67px 0 87px;

        &__content {
            margin-right: -22px;
            margin-left: -22px;
        }

        &__col {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            padding-right: 22px;
            padding-left: 22px;
        }	

        &__item {
            padding-bottom: 32px;
        }

        &__photo {
            margin-bottom: 28px;
    
            IMG {
                border-radius: 8.87619px 0;
            }
        }

        &__name {
            font-size: 24px;
            line-height: 118%;
            margin-bottom: 7px;
        }

        &__post {
            line-height: 133%;
            letter-spacing: 0;
        }
    }

    @media (max-width: 991px) {

        &__content {
            margin-right: -8px;
            margin-left: -8px;
        }

        &__col {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 8px;
            padding-left: 8px;
        }
        
        &__item {
            padding-bottom: 35px;
        }

        &__photo {
            margin-bottom: 26px;
    
            IMG {
                border-radius: 5.65118px 0;
            }
        }

        &__name {
            font-size: 22px;
            line-height: 122%;
        }

        &__post {
            font-size: 12px;
            line-height: 130%;
        }
        
    }

    @media (max-width: 767px) {

        padding: 59px 0 49px;
        
    }

    @media (max-width: 575px) {
        
    }
}
   