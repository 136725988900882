.news {

	@media (max-width: 1199px) {

        padding: 56px 0 70px 0;
        
        &__title {
            margin-bottom: 27px;
        }

		&__content {
			margin-right: -10px;
			margin-left: -10px;
			margin-bottom: 12px;
		}
	
		&__col {
			padding-right: 10px;
			padding-left: 10px;
			padding-bottom: 20px;
		}

		&__info {
			left: 25px;
			right: 25px;
			bottom: 25px;
		}

		&__date {
			font-size: 16px;
			line-height: 19px;
			margin-bottom: 9px;
		}
	
		&__name {
			font-size: 18.0688px;
			line-height: 123%;
			
			.main & {
				font-size: 32px;
				line-height: 111%;
				margin-bottom: 6px;
			}
		}
	
		&__text {
			font-size: 16px;
			line-height: 128%;
		}
	}

	@media (max-width: 991px) {

	}

	@media (max-width: 767px) {

        padding: 59px 0 70px 0;

        &__title {
            margin-bottom: 30px;
        }

		&__content {
			flex-direction: column;
			align-items: center;
		}

		&__col {
			flex: 0 0 100%;
			max-width: 100%;
        }
        
        &__item { 
            max-width: 576px;

            .main & {
				max-width: none;
			}
        }

		&__info {
			left: 20px;
			right: 20px;
			bottom: 20px;
        }
        
        &__hashtag {
            display: none;
        }

		&__date {
			font-size: 12px;
			line-height: 15px;
			margin-bottom: 7px;
		}
	
		&__name {
			font-size: 16px;
			line-height: 109%;
			
			.main & {
				font-size: 16px;
				line-height: 109%;
				margin-bottom: 12px;
			}
		}
	
		&__text {
			display: none;
		}
	}

	@media (max-width: 575px) {

	}

	@media ( max-width: 425px ) {

	}
}   