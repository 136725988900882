.partners {

    @media (max-width: 1199px) {

        padding: 0 0 87px;

        &__title {
            margin-bottom: 27px;
        }

        &__list {

            LI {
                font-size: 18px; 
            }
        }
    }

    @media (max-width: 991px) {

    }

    @media (max-width: 767px) {

        padding: 0 0 49px;

        &__title {
            margin-bottom: 30px;
        }

        &__list {
            flex-direction: column;
            gap: 20px;

            LI {
                font-size: 16px; 
            }
        }
    }

    @media (max-width: 575px) {

    }
}