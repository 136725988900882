.header {

    @media (max-width: 1399px) {

        &__menu {
    
            &-item {
                font-size: 15px;
                margin: 0 10px;
            }
        }

        .search {
        
            &__form {
        
                &-input {
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 1199px) {

        // position: absolute;

        &__icon {
            display: block;
            margin-right: 24px;
        }

        &_nofix & {
        
            &__icon {
                display: none
            }
        }

        &__logo {
            max-width: 218px;
        }

        &__menu {
            display: none;
            position: fixed;
            z-index: 10;
            top: 80px;
            bottom: 0;
            left: 0;
            right: 0;
            background: #fff;
            padding: 76px 15px 30px;
            max-width: 325px;
            height: 100%;
            max-height: 500px;
            min-height: 350px;
            overflow-y: scroll;
            

            &-item {
                font-size: 16px;
                margin: 0 0 48px 0;
            }
        }

        &__basket {
            font-size: 16px;
            line-height: 133.98%;
            margin-right: 38px;
    
            &:before {
                width: 20px;
                height: 20px;
                background-size: 39px auto;
                background-position: 0 -56px;
                vertical-align: -4px;
            }
        }
    
        &__profile {
            width: 44px;
            height: 44px;
            background-size: 44px auto;
        }

        .search {
            max-width: 50%;
            padding-top: 5px;
        
            .page-scrolled & {
                padding: 0 0 10px 0;
            }

            &__form {
                height: 50px;
        
                .page-scrolled & {
                    height: 40px;
                }

                &:before {
                    left: 27px;
                    width: 24px;
                    height: 24px;
                    background-position: 0 -150px;
                    background-size: 40px auto;
                }
        
                &-item {
                    padding: 0 10px 0 68px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        
    }

    @media (max-width: 767px) {

        &__box {
            min-height: 74px;
    
            .page-scrolled & {
                min-height: 64px;
            }
        }

        &__logo {
            max-width: 170px;
        }

        &__menu {
            top: 50px;
        }

        &__basket {
            display: none;
        }

        &__profile {
            width: 24px;
            height: 24px;
            background-size: 24px auto;
        }

        .search {
            max-width: 100%;

            &__form {
                height: 32px;
                border-radius: 8.72224px;
        
                .page-scrolled & {
                    height: 32px;
                }

                &:before {
                    left: 15px;
                    width: 15px;
                    height: 15px;
                    background-position: 0 -93px;
                    background-size: 25px auto;
                }
        
                &-item {
                    padding: 0 10px 0 42px;
                }
        
                &-input {
                    font-size: 12px;
                }
            }
        }
    }

    @media (max-width: 575px) {

    }

    @media (max-width: 360px) {

        &__menu {
            padding: 76px 30px 30px;
        }
    }
}  