.books {

    @media (max-width: 1199px) {

        padding: 87px 0 71px 0;

        &__panel {
            margin-bottom: 37px;
        }

        &__head {
            margin-right: 98px;
        }

        .tabs {
    
            &__title {
                font-size: 16px;
    
                SPAN {
                    padding-top: 0px;
                }
            }
        }

        &__col {
            flex: 0 0 25%;
            max-width: 25%;

            &_whide {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        &__label {
            top: -14px;
            right: 10px;
            height: 29px;
            padding: 0 12px;
            font-size: 13px;

            &_discount {
                font-weight: 600;
                font-size: 15px;
            }
        }

        &__photo {
            margin-bottom: 23px;
        }

        &__price {
            font-size: 16px;
            line-height: 107%;
            margin-bottom: 17px;
        }
    
        &__name {
            font-size: 20px;
            line-height: 89%;
            margin-bottom: 6px;
        }

        &__text {
            font-size: 16px;
            line-height: 125%;
        }

        &__settitle {
            font-size: 32px;
            line-height: 107.18%;
        }
    
        &__settext {
            font-size: 16px;
            line-height: 29.09px;
            margin-bottom: 14px;
        }
    
        &__setprice {
            font-size: 20px;
            line-height: 102%;
        }

        &__col_whide & {

            &__item {
                
                &-inner {
                    padding: 43px 40px;
                }
            }
        }
    }

    @media (max-width: 991px) {

        &__panel {
            flex-direction: column;
            margin-bottom: 23px;
        }
    
        &__head {
            margin: 0 0 25px 0;
        }

        &__content {
            margin-right: -10px;
            margin-left: -10px;
        }

        &__col {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 10px;
            padding-left: 10px;

            &_whide {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        &__label {
            padding: 0 5px;
            font-size: 12px;

            &_discount {
                font-size: 14px;
            }
        }

        &__settitle {
            font-size: 20px;
        }
    
        &__settext {
            font-size: 10.82px;
            line-height: 19.68px;
            margin-bottom: 10px;
        }
    
        &__setprice {
            font-size: 18px;
            line-height: 102%;
        }

        &__col_whide & {

            &__item {
                
                &-inner {
                    padding: 29px;
                }
            }
        }
        
    }

    @media (max-width: 767px) {

        padding: 64px 0 70px 0;

        .tabs {
            display: block;
        }
    }

    @media (max-width: 575px) {

    }
}