.topbar {

    @media (max-width: 1199px) {

        &__box {
            max-width: 832px;
            padding-left: 32px;
        }

        &__left {
            flex: 0 0 289px;
        }
    
        &__right {
            width: 100%;
            max-width: 750px;
            padding-left: 30px;
        }

        &__contacts {
            padding: 27px 21px 10px 24px;
            border-radius: 4.65069px;
            font-size: 12px;

            &-title {
                font-size: 16px;
                margin-bottom: 9px;
            }
    
            &-description {
                line-height: 155%;
                margin-bottom: 12px;
            }

            &-list {

                &-title {
                    padding-top: 12px;
                    font-size: 12px;
                    margin-bottom: 9px;
                }
    
                &-value {
                    line-height: 120%;
                    margin-bottom: 12px;
                }
            }
        }

        &__menu {
            padding-top: 28px;

            &-block {
                padding-bottom: 16px;
            }

            &-title {
                font-size: 12px;
                margin-bottom: 14px;
            }

            &-item {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 13px;
            }
        }
    }

    @media (max-width: 991px) {

        &__box {
            padding-left: 0;
        }

        &__menu {

            &-title {
                font-size: 11px;
            }

            &-item {
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 11px;
            }
        }
    }

    @media (max-width: 767px) {

        &__box {
            flex-direction: column;
        }

        &__left {
            flex: 0 0 100%;
        }
    
        &__right {
            width: 100%;
            max-width: none;;
            padding-left: 0;
        }
    }

    @media (max-width: 575px) {

        &__menu {
            flex-wrap: wrap;
            padding-top: 38px;
    
            &-col {
                flex: 0 0 50%;

                &:last-child {
                    display: flex;
                    flex: 0 0 100%;

                    .topbar__menu-block {
                        flex: 0 0 50%;
                    }
                }
            }

            &-block {
                padding-bottom: 20px;
            }

            &-title {
                font-size: 10.883px;
                line-height: 13px;
                margin-bottom: 10px;
            }

            &-item {
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 10px;
            }
        }
        &__trigger {

            &-text {
                
                &_desk {
                    display: none;
                }
    
                &_mob {
                    display: inline;
                }
            }
        }
    }

    @media (max-width: 360px) {

        &__box {
            max-width: 300px;
        }

        
    }
}
   