.footer {

    @media (max-width: 1199px) {

        // padding: 0 0 100px 0;

        &__box {
            // max-width: 832px;
            // // padding-left: 32px;
            // font-size: 10.8516px;
            // line-height: 143%;
        }

        &__copy {
            // flex: 0 0 289px;
        }
    
        &__policy {
            // padding-left: 30px;
        }
    }

    @media (max-width: 991px) {

        &__box {
            padding-left: 0;
        }
    }

    @media (max-width: 767px) {

        &__box {
            // flex-direction: column;
        }

        &__copy {
            // flex: 0 0 100%;
        }
    
        &__policy{
            // width: 100%;
            // max-width: none;;
            // padding-left: 0;
        }
    }

    @media (max-width: 575px) {
        
    }

    @media (max-width: 360px) {

        &__box {
            // max-width: 300px;
        }
    }
}
   