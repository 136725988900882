.cooperation {

    @media (max-width: 1699px) {

        &__right {
            padding: 5% 4% 0 4%;
        }
    }

    @media (max-width: 1199px) {

        &__box {
            flex-direction: column;
        }

        &__left {
            flex: 0 0 100%;
            padding: 80px 59px 88px 59px;
            background: linear-gradient(95.05deg, #5957B1 31.53%, #4595B7 105.34%);
        }

        &__right {
            flex: 0 0 100%;
            padding: 107px 5% 45px 5%;
    
            &:before {
                top: -68px;
                width: 286px;
                height: 147px;
                background-size: 100% auto;
            }
        }

        &__title {
            margin-bottom: 31px;
        }
    }

    @media (max-width: 991px) {
        
    }

    @media (max-width: 767px) {

        &__left {
            padding: 59px 32px;
        }

        &__right {
            padding: 75px 32px 52px 32px;
    
            &:before {
                top: -39px;
                width: 164px;
                height: 85px;
            }
        }

        &__description {
            margin-bottom: 33px;
        }

        &__item {
            font-size: 17px;
            line-height: 141%;
        }

        &__text {
            font-size: 20px;
            line-height: 110%;
            margin-bottom: 7px;
        }
    
        &__title {
            font-size: 36px;
            margin-bottom: 35px;
        }
    }

    @media (max-width: 575px) {
        
    }

    @media (max-width: 360px) {

        &__box {
            margin: 0 -45px;
        }

        &__left {
            padding: 59px 45px;
            border-radius: 0;
        }

        &__right {
            padding: 75px 45px 52px 45px;
            border-radius: 0;
        }
    }
}
   