.main {

    @media (max-width: 1499px) {

        &__item {

            &-figure {
                max-width: 60%;
                left: 32%;
            }
           
            &-book {
                max-width: 20%;
            }
        }
    }

    @media (max-width: 1199px) {

        padding-top: 105px;

        &__carousel {
    
            .slick-slide {
                height: auto;
                min-height: 458px;
            }
        }

        &__item {

            &-box {
                padding: 0 87px 0 59px;
            }

            &-info {
                flex: 0 0 55%;
                padding: 51px 0 40px 0;
            }

            &-subtitle {
                font-size: 10px;
                line-height: 120%;
                margin-bottom: 32px;
            }
    
            &-author {
                font-size: 24px;
                line-height: 112%;
                margin-bottom: 13px;
            }
    
            &-title {
                font-size: 44px;
                line-height: 109%;
            }
    
            &-text {
                font-size: 14px;
                line-height: 143%;
                margin-bottom: 34px;
            }

            &-figure {
                max-width: 80%;
                left: 40%;

                &-img {

                    &_desk {
                        display: none !important;
                    }
                
                    &_mob {
                        display: block !important;
                    }
                }
            }
           
            &-book {
                display: none;
            }
        }
    }

    @media (max-width: 991px) {
        
    }

    @media (max-width: 767px) {

        padding-top: 25px;
        
        &__carousel {
            margin: 0 -15px;
            padding-bottom: 20px;
        }

        .slick-arrow  {
            display: none !important;
        }

        &__item {
            border-radius: 0;
            overflow: hidden;
            &-title {
                font-size: 36px;
                line-height: 109%;
            }
            &-box {
                min-height: none;
                padding: 0 30px 0 30px;
            }

            &-info {
                flex: 0 0 70%;
                padding: 37px 0 40px 0;
            }

            &-figure {
                left: auto;
                right: -20%;
                max-width: 90%;

                &-img {

                    &_desk {
                        display: none !important;
                    }
                
                    &_mob {
                        display: block !important;
                    }
                }
            }
        }
        
    }

    @media (max-width: 575px) {
        
    }
}
   