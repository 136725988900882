.form {

    @media (max-width: 991px) {

        &_horisontal & {

            &__item {
                margin-right: 10px;

                &:last-child {margin-right: 0;}
            }

            &__button {font-size: 16px;}
        }
    } // 991

    @media (max-width: 767px) {

        &__items {
            flex-flow: column;
        }

        &__item {
            min-height: 60px;
    
            &_textarea {
                min-height: 148px;
            }
        }

        &__input {
            width: 100%;
            font-size: 14px;
        }

        &_vertical {
            max-width: 428px;
            margin: 0 auto;
        }

        &_horisontal {
            max-width: 428px;
            margin:0 auto;
        }

        &_horisontal & {

            &__item {margin: 0;}
        }
    } // 767
}