.bottom-menu {

    @media (max-width: 1199px) {

        .page-scrolled & {
            display: block;
            
        }
    }

    @media (max-width: 991px) {
        
    }

    @media (max-width: 767px) {

        padding: 9px 0 8px 0;

    }

    @media (max-width: 575px) {
        
    }
}